import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { TableDataService } from './table-data.service';
import { DataTableService } from '../../services/data-table.service';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Company } from 'src/app/model/company.interface';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';

@Component({
  selector: 'app-data-table',
  providers: [DataTableService, TableDataService],
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() apiUrl: string = '';
  @Input() columnHeader: any;
  @Input() pageSize: number = 0;
  @Input() pageSizeOptions: number[] = [];
  @Input() sortDirection: SortDirection = '';
  @Output() rowClicked: EventEmitter<Company> = new EventEmitter<Company>();
  length: number = 0;

  objectKeys = Object.keys;
  dataSource!: TableDataService;

  searchInput: FormControl = new FormControl('');
  _ngUnsubscribe$: Subject<void> = new Subject<void>();
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private dataTableService: DataTableService,
              private errorHandlingService: ErrorHandlingService) { }

  ngOnInit(): void {
    this.dataSource = new TableDataService(this.dataTableService, this.errorHandlingService);
    this.dataSource.loadData(this.apiUrl, '', this.sortDirection, 1, this.pageSize);
    this.dataSource.length$.subscribe(total => this.length = total);
  }

  ngAfterViewInit(): void {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage(this.searchInput.value))
      )
      .subscribe();

    this.searchInput.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe$),
    ).subscribe((searchTerm: string) => {
      this.paginator.pageIndex = 0;
      this.loadDataPage(searchTerm);
    });
  }

  onRowClicked(row: Company): void {
    this.rowClicked.emit(row);
  }

  loadDataPage(searchTerm?: string): void {
    this.dataSource.loadData(
      this.apiUrl,
      searchTerm,
      this.sortDirection,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
