import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from '../model/company.interface';
import { Pagination } from '../model/pagination.interface';

@Injectable()
export class DataTableService {
  constructor(private http: HttpClient) {}

  getData(
    apiUrl: string,
    filter: string = '',
    sortedByName: string = '',
    pageNumber: number = 1,
    pageSize: number = 10
  ): Observable<Pagination<Company>> {
    let params = new HttpParams();

    if (filter !== '') {
      params = params.append('searchBy', filter);
    }
    if (sortedByName !== '') {
      params = params.append('sortByNameriteria', sortedByName);
    }
    params = params.append('page', pageNumber.toString());
    params = params.append('limit', pageSize.toString());

    return this.http.get<Pagination<Company>>(apiUrl, { params }).pipe(
      map((res) => res)
    );
  }
}
