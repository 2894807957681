import { Endpoints } from 'src/app/model/endpoints.interface';

export function endpoints(url: string, apiVersion: string): Endpoints {
  return {
    auth:                     `${url}/auth`,
    refresh:                  `${url}/auth/refresh`,
    logout:                   `${url}/${apiVersion}/refresh_tokens`,
    customers:                `${url}/${apiVersion}/customers`,
    self:                     `${url}/${apiVersion}/_self`,
    companies:                `${url}/${apiVersion}/companies`,
    branches:                 `${url}/${apiVersion}/branches`,
    locations:                `${url}/${apiVersion}/locations`,
    platforms:                `${url}/${apiVersion}/job_platforms`,
    countries:                `${url}/${apiVersion}/countries`,
    kpi:                      `${url}/${apiVersion}/admin/kpi`,
    statuses:                 `${url}/${apiVersion}/application_hiring_statuses`,
    candidates:               `${url}/${apiVersion}/candidates`,
    applications:             `${url}/${apiVersion}/applications`,
    enterprises:              `${url}/${apiVersion}/enterprises`,
    enterpriseManager:        `${url}/${apiVersion}/enterprise_managers`,
    jobCategories:            `${url}/${apiVersion}/job_categories`,
    admins:                   `${url}/${apiVersion}/admins`,
    adminCompanies:           `${url}/${apiVersion}/admin/companies`,
    universalJobs:            `${url}/${apiVersion}/universal_jobs`,
    auth2fa_totp:             `${url}/auth2fa_totp`,
  };
}
