import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private _loaderStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  show(): void {
    this._loaderStatus$.next(true);
  }

  hide(): void {
    this._loaderStatus$.next(false);
  }

  get loader(): Observable<boolean> {
    return this._loaderStatus$.asObservable();
  }
}
