import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multiselect-autocomplete',
  templateUrl: './multiselect-autocomplete.component.html',
  styleUrls: ['./multiselect-autocomplete.component.scss']
})
export class MultiselectAutocompleteComponent {

  rawData: any[] = [];
  filteredData: any[] = [];
  selectData: any[] = [];

  @Output() outcome: EventEmitter<any> = new EventEmitter<any>();

  @Input() placeholder: string = 'Select Data';
  @Input() multiselect: boolean = true;
  @Input() set data(data: any[]) {
    if (data.length) {
      data?.forEach((item) => {
        this.rawData.push({ ...item, selected: false });
      });
      this.filteredData = this.filterData();
    }
  };
  @Input() selectedFromOutsideKey: string = 'id';
  @Input() filterKey: string = '';

  constructor() {}

  onInputChange(event: Event): void {
    this.filteredData = this.filterData(event.target as HTMLInputElement);
  }

  filterData(event?: HTMLInputElement): any[] {
    if (event?.value) {
      const filterValue = event.value.toLowerCase();
      return this.rawData.filter((item) => {
        return item[this.filterKey].toLowerCase().indexOf(filterValue) >= 0;
      });
    } else {
      return this.rawData.slice();
    }
  }
  ​
  optionClicked(event: Event, data: any): void {
    event.stopPropagation();
    this.toggleSelection(data);
  };

  toggleSelection(data: any): void {
    // if we want to use multiselect as regular select/single

    if (!this.multiselect && this.selectData.length) {
      if (!data.selected) {
        this.selectData[0].selected = !this.selectData[0].selected;
      }
      this.selectData = [];
    }

    data.selected = !data.selected;

    if (data.selected) {
      this.selectData.push(data);
    } else {
      const i = this.selectData.findIndex(value => value.id === data.id);
      if (i > -1) {
        this.selectData.splice(i, 1);
      }
    }
  ​
    this.outcome.emit({
      allSelectedData: this.selectData,
      selectedData: data
    });
  };
}
