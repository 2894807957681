import { NgModule } from '@angular/core';
import { DataTableComponent } from './data-table/data-table.component';
import { MaterialModule } from '../modules/layout/material/material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { TrimValueAccessorDirective } from './trim.directive';
import { ListComponent } from './list/list.component';
import { MultiselectAutocompleteComponent } from './multiselect-autocomplete/multiselect-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ColorPickerModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DataTableComponent,
    ColorPickerModule,
    TrimValueAccessorDirective,
    ListComponent,
    MultiselectAutocompleteComponent
  ],
  declarations: [
    ListComponent,
    DataTableComponent,
    TrimValueAccessorDirective,
    MultiselectAutocompleteComponent
  ],
  providers: [],
})
export class SharedModule {}
