<mat-form-field>
  <mat-label>Filter</mat-label>
  <input type="text"
         matInput
         [formControl]="searchInput">
</mat-form-field>

<div class="data-table-wrapper">
  <table mat-table
         [dataSource]="dataSource"
         matSort
         [matSortDirection]="sortDirection"
         matSortDisableClear
         class="mat-elevation-z8">
    <ng-container [matColumnDef]="tableData"
                  *ngFor="let tableData of objectKeys(columnHeader)">
      {{tableData}}
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header>{{columnHeader[tableData]}}</th>
      <td mat-cell
          *matCellDef="let element">{{element[tableData] }}</td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="objectKeys(columnHeader)"></tr>

    <tr mat-row
        *matRowDef="let row; columns: objectKeys(columnHeader);"
        (click)="onRowClicked(row)"></tr>
  </table>
</div>

<div class="spinner-container"
     *ngIf="dataSource.loading$ | async">
  <mat-spinner></mat-spinner>
</div>
<mat-paginator [length]="length"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions">
</mat-paginator>
